<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="项目分类:" prop="projType">
            <el-select
              v-model="searchModel.projType"
              placeholder="请选择项目分类"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option label="普通" value="1"></el-option>
              <el-option label="重点" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程名称:" prop="projName">
            <el-input v-model="searchModel.projName" clearable></el-input>
          </el-form-item>
          <el-form-item label="建设单位:" prop="builFirm">
            <el-select
              v-model="searchModel.builFirm"
              placeholder="请选择建设单位"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="item in relaItems"
                :key="item.relaId"
                :label="item.firm"
                :value="item.relaId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划开工时间:">
            <el-date-picker
              v-model="searchModel.initDatStart"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
            --
            <el-date-picker
              v-model="searchModel.initDatEnd"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="priceProjMan"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :data="tableData">
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'价格信息_工程管理_新增'"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;projEditModel = undefined"
            >新增工程
            </el-button
            >
          </template>
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="projName" title="工程名称" show-overflow="title" min-width="60"
                            show-header-overflow="title"/>
          <vxe-table-column field="address" title="建设地址" show-overflow="title" min-width="60"
                            show-header-overflow="title"/>
          <vxe-table-column field="consLiceNum" title="施工许可证电子证照编号" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="projType" title="项目分类" show-overflow="title" min-width="120"
                            show-header-overflow="title" :formatter="
                              ({ cellValue }) => {
                                return cellValue ===1 ? '普通': '重点';
                              }"/>
          <vxe-table-column field="cityName" title="项目属地" min-width="60" show-overflow="title"
                            show-header-overflow="title" :formatter="
                              ({ cellValue, row, column }) => {
                                return cellValue ==='直辖市' ? row.provinName+row.cityName: row.provinName+row.cityName+row.countrName;
                              }"/>
          <vxe-table-column field="builFirmName" title="建设单位" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="initDat" title="计划开工日期" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="coveArea" title="建设规模（m2）" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="success" icon="el-icon-tickets" circle size="mini" @click="detailRow(row)"
                         v-auth="'价格信息_工程管理_详情'" title="详情"/>
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)"
                         v-auth="'价格信息_工程管理_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'价格信息_工程管理_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <!-- 编辑工程 -->
      <edit-proj :visible.sync="dialogEdit" :edit-data.sync="projEditModel" @refresh="refreshData" :rela-items="relaItems" :region-options="regionOptions" :region-props="regionProps" :region-arr="regionArr"/>
      <el-dialog title="工程详情"
                 :visible.sync="dialogDetail"
                 :v-if="dialogDetail"
                 :append-to-body="true"
                 width="860px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="200px"
                 style="margin-top: 20px">
          <el-row>
            <el-form-item label="上传电子证照">
              <!--            <el-upload
              ref="uploadRef"
              :action="url"
              :on-change="uploadSuccess"
              :http-request="uploadPicture"
              :show-file-list="false"
              :limit="1"></el-upload>-->
              <el-button size="small" type="primary">上传电子证照</el-button>
            </el-form-item>
            <el-form-item v-if="projDetailModel.imgURL||projDetailModel.imgURL!=''">
              <img :src="getMediaUrl(projDetailModel.imgURL)" class="avatar">
            <!--            <el-link class="ml-10" @dblclick.native="downloadMedia" target="_blank" type="info" title="双击预览附件">
              <i class="el-icon-link"></i>
              已传附件
            </el-link>-->
            <!--            <el-icon class="delete-pdf el-icon-delete ml-5" @click.native="projDetailModel.imgURL =''"></el-icon>-->
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="工程名称:" props="projName">
              <el-input v-model="projDetailModel.projName"  style="width: 180px" :title="projDetailModel.projName"></el-input>
            </el-form-item>
            <el-form-item label="建设地址:" props="address">
              <el-input
                v-model="projDetailModel.address"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="工程项目编号:" props="projNum">
              <el-input
                v-model="projDetailModel.projNum"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="施工许可证电子证照编号:" props="consLiceNum">
              <el-input
                v-model="projDetailModel.consLiceNum"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="项目分类:" props="busLicPlate">
              <el-select
                v-model="projDetailModel.projType"
                placeholder="请选择项目分类"
                filterable
                clearable
                style="width: 180px"
              >
                <el-option label="普通" value="1"></el-option>
                <el-option label="重点" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目地址:" props="address">
              <el-input
                v-model="projDetailModel.address"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="项目负责人:" props="lastLinkName">
              <el-input
                v-model="projDetailModel.lastLinkName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="项目负责人联系方式:" props="lastLinkPhone">
              <el-input
                v-model="projDetailModel.lastLinkPhone"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="计划开工日期:" props="initDat">
              <el-input
                v-model="projDetailModel.initDat"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="计划竣工时间:" props="complDat">
              <el-input
                v-model="projDetailModel.complDat"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="合同价格（万元）:" props="price">
              <el-input
                v-model="projDetailModel.price"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="总面积（平方米）:" >
              <el-input
                v-model="projDetailModel.coveArea"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="合计地上面积（平方米）:">
              <el-input
                v-model="projDetailModel.aboveArea"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="合计地下面积（平方米）:" prop="underArea">
              <el-input
                v-model="projDetailModel.underArea"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="管理属地:">
              <el-cascader
                ref="editCascader"
                v-model="regionArr"
                clearable
                placeholder="请选择区域"
                :options="regionOptions"
                :props="regionProps"
                @change="handleItemChange"
                style="width: 180px"
                filterable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="建设规模:" prop="coveArea">
              <el-input
                v-model="projDetailModel.coveArea"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="预计混凝土用量（立方）:" props="mortar">
              <el-input
                v-model="projDetailModel.concrete"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="预计砂浆用量（吨）:" props="mortar">
              <el-input
                v-model="projDetailModel.mortar"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <p class="fs-16 bold mb-15">参加单位信息</p>
          </el-row>
          <el-row>
            <el-table border :data="projDetailModel.relaList" style="width: 100%">
              <el-table-column  prop="relaType" label="承担角色" :formatter="(row) => {
                return row.relaType ? relaTypeOptions[row.relaType]:'';
              }">
              </el-table-column>
              <el-table-column prop="uniteCredit" label="企业统一社会信用代码">
              </el-table-column>
              <el-table-column prop="firm" label="单位名称" style="width:6vw;" >
              </el-table-column>
              <el-table-column prop="linkName" label="负责人姓名" style="width:6vw;" >
              </el-table-column>
              <el-table-column prop="linkCard" label="项目负责人证件号码" style="width:6vw;" >
              </el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <p class="fs-16 bold mb-15">单体项目信息</p>
          </el-row>
          <el-row>
            <el-table border :data="projDetailModel.projMonList" style="width: 100%" >
              <el-table-column  prop="monName" label="单体名称"></el-table-column>
              <el-table-column prop="monParam" label="单体参数" style="width:6vw;" ></el-table-column>
              <el-table-column prop="monOther" label="单体其他参数" style="width:6vw;"></el-table-column>
            </el-table>
          </el-row>
        </el-form>
      </el-dialog>
      <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
    </div>
  </page-container>
</template>

<script>
import http from '@/utils/http'
import editProj from './components/editProj'
import { getProjecctList, deleteProjecct, getRelaOne, getProjecctOne } from '@/apis/priceManage/projectMan'
import { gianPerscsMore, gainRegi } from '@/apis/common'
import XEUtils from 'xe-utils'
import QrCode from '@/components/QrCode'

export default {
  name: 'project-manage',
  data () {
    return {
      http: http,
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      reload: false, // 刷新 保证可以重复选同一个文件
      currentRelaIndex: 0,
      isRelaMenu: false,
      dialogDetail: false,
      editMode: 'add',
      isProjMenu: false,
      projDetailModel: {},
      regionArr: [],
      // 所属区域
      selectedOptions: [],
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      searchVal: '',
      personMap: new Map(),
      loading: false,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      searchModel: {
        builFirm: '',
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: '',
        projName: '',
        projType: '',
        initDatEnd: '',
        initDatStart: ''
      },
      dialogEditTitle: '新增工程',
      dialogEdit: false,
      projEditModel: {
      },
      relaTypeOptions: {
        1: '建设',
        2: '设计',
        3: '施工',
        4: '监理',
        5: '质监'
        /* 10: '企业下客户',
        20: '企业下供应商',
        30: '机构下质检',
        40: '保险公司',
        50: '设备供应商' */
      },
      relaItems: [],
      picUrl: '',
      qrCodeContent: '',
      codeVisible: false
    }
  },
  computed: {
    search () {
      var item = this.items.filter(ele => {
        if (ele.orgName.match(this.searchVal)) {
          return ele
        }
      })
      return item
    }
  },
  methods: {
    handlerQrcode (row) { // 项目二维码：项目名称、施工单位、施工许可证号
      this.codeVisible = true
      this.qrCodeContent = `项目名称: ${row.projName};施工单位: ${row.builFirmName};施工许可证号: ${row.consLiceNum};`
    },
    // 上传图片
    uploadPicture (content) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.picUrl = http.baseUrl + '/' + res.data.nowRelatUrl
        this.projEditModel.imgURL = res.data.nowRelatUrl
        console.log(555, this.picUrl)
        // this.loading = false
      })
    },

    uploadSuccess () {
      console.log('uploadSuccess')
      this.reload = true
      this.$nextTick(() => {
        this.reload = false
      })
    },
    removeUnitRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    removeMonRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    handleItemChange (val) {
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      //  this.loading = true;
      this.tableData = []
      // this.currentBusIndex = 0;
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    detailRow (row) {
      getProjecctOne({
        projId: row.projId
      }).then(res => {
        const details = res.data
        const rowData = {}
        XEUtils.objectEach(details, (item, key) => {
          if (key == 'relaList' || key == 'projMonList') {
            rowData[key] = details[key]
          } else {
            rowData[key] = details[key] ? details[key].toString() : ''
          }
        })
        this.regionArr = []
        if (details.regiCountrId) {
          gainRegi({
            isMapJson: false,
            regiLevel: 2,
            regiDto: {} /*  */
          }).then((res) => {
            const targetProvin = this.regionOptions.find(
              (item) => item.value == row.regiProvinId
            )
            let targetCity = res.data.find(
              (item) => item.regiCityId == row.regiCityId
            )
            targetCity = {
              value: targetCity.regiCityId,
              label: targetCity.cityName,
              code: targetCity.cityCode,
              children: targetCity.regiCountrs.map((item) => {
                return {
                  value: item.regiCountrId,
                  label: item.countrName,
                  code: item.countrCode,
                  leaf: true
                }
              })
            }
            targetProvin.children.forEach((item, index) => {
              if (item.value == details.regiCityId) {
                this.$set(targetProvin.children, index, targetCity)
              }
            })
          })
        }
        if (details.regiProvinId != '' || details.regiProvinId != '0') {
          this.regionArr.push(details.regiProvinId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCityId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCountrId)
        }
        this.projDetailModel = Object.assign({}, rowData)
        this.dialogDetail = true
      })
    },
    editRow (row) {
      getProjecctOne({
        projId: row.projId
      }).then(res => {
        const details = res.data
        const rowData = {}
        XEUtils.objectEach(details, (item, key) => {
          if (key == 'relaList') {
            const tempArr = []
            details[key].forEach(eve => {
              tempArr.push({
                linkName: eve.linkName,
                linkPhone: eve.linkPhone,
                firm: eve.firm,
                relaType: eve.relaType,
                uniteCredit: eve.uniteCredit,
                accoId: eve.accoId,
                linkCard: eve.linkCard
              })
            })
            rowData[key] = tempArr
          } else if (key == 'projMonList') {
            const tempArr = []
            details[key].forEach(eve => {
              tempArr.push({
                monName: eve.monName,
                monOther: eve.monOther,
                monParam: eve.monParam,
                projId: eve.projId,
                projMonId: eve.projMonId
              })
            })
            rowData[key] = tempArr
          } else {
            rowData[key] = details[key] ? details[key].toString() : ''
          }
        })
        this.regionArr = []
        if (details.regiCountrId) {
          gainRegi({
            isMapJson: false,
            regiLevel: 2,
            regiDto: {} /*  */
          }).then((res) => {
            const targetProvin = this.regionOptions.find(
              (item) => item.value == details.regiProvinId
            )
            let targetCity = res.data.find(
              (item) => item.regiCityId == details.regiCityId
            )
            targetCity = {
              value: targetCity.regiCityId,
              label: targetCity.cityName,
              code: targetCity.cityCode,
              children: targetCity.regiCountrs.map((item) => {
                return {
                  value: item.regiCountrId,
                  label: item.countrName,
                  code: item.countrCode,
                  leaf: true
                }
              })
            }
            targetProvin.children.forEach((item, index) => {
              if (item.value == details.regiCityId) {
                this.$set(targetProvin.children, index, targetCity)
              }
            })
          })
        }
        if (details.regiProvinId != '' || details.regiProvinId != '0') {
          this.regionArr.push(details.regiProvinId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCityId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCountrId)
        }
        this.projEditModel = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该工程?', '删除工程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deleteProjecct({
          ids: [row.projId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    refreshRelaCallback () {
      getRelaOne({
      }).then(res => {
        if (res.code == 200) {
          const relaItems = []
          res.data.forEach(item => {
            const temp = item
            if (item.uniteCredit) {
              temp.value = item.uniteCredit
              relaItems.push(temp)
            }
          })
          this.relaItems = relaItems
        }
      })
    },
    refreshData () {
      this.loading = true
      getProjecctList({
        ...this.searchModel,
        regiCountrId: this.selectedOptions[2]
          ? this.selectedOptions[2]
          : undefined,
        regiCityId: this.selectedOptions[1]
          ? this.selectedOptions[1]
          : undefined,
        regiProvinId: this.selectedOptions[0]
          ? this.selectedOptions[0]
          : undefined,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.tablePage.total = res.data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    }
  },
  mounted () {
    // 所属区域
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then((res) => {
      if (res.code == 200) {
        const regionOptions = res.data.map((val) => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map((valCity) => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
        this.regionOptions = regionOptions
      }
    })
      .catch((val) => {
        console.log(val)
      })
    // 关联人员选择
    gianPerscsMore({}).then(res => {
      /*  if (res.code == 200) {
          const personMap = new Map()
          res.data.forEach(rowData => {
            personMap.set(rowData.persId, rowData)
          })
          this.personMap = personMap
        } */
    }).catch(res => {
      console.log(res)
    })
    this.refreshRelaCallback()
    this.refreshData()
  },
  components: {
    editProj,
    QrCode
  }
}
</script>

<style lang="scss" scoped>
</style>
